import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import TimerIcon from "@material-ui/icons/Timer";
import SaveIcon from "@material-ui/icons/Save";
import withWidth from "@material-ui/core/withWidth";

const styles = {
  root: {
    margin: "40px 10px",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "95%",
    maxWidth: "400px"
  },
  input: {
    marginLeft: 8,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4
  }
};

function CustomizedInputBase(props) {
  const { classes } = props;

  return (
    <Paper className={classes.root} elevation={1}>
      {/*
      <IconButton className={classes.iconButton} aria-label="Menu">
        <MenuIcon />
      </IconButton>
      */}
      <InputBase className={classes.input} placeholder="http:// or https://" />
      <IconButton className={classes.iconButton} aria-label="Search">
        <TimerIcon />
      </IconButton>
      {/*
      <Divider className={classes.divider} />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="Directions"
      >
        <SaveIcon />
      </IconButton>
      */}
    </Paper>
  );
}

CustomizedInputBase.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomizedInputBase);
