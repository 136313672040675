import React from "react";
const Logotype = ({ fontSize, padding }) => (
  <div style={{ fontSize, padding, fontFamily: "Orbitron, sans-serif" }}>
    benchy{" "}
  </div>
);

Logotype.defaultProps = {
  fontSize: "50px",
  padding: "10px"
};
export default Logotype;
